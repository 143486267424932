const envConfig = {
  REACT_APP_API_TOKEN: process.env.REACT_APP_API_TOKEN ?? '#{REACT_APP_API_TOKEN}#',
  REACT_APP_API_URL: process.env.REACT_APP_API_URL ?? 'https://ingram-grocefy-api-prod.azurewebsites.net/api/',
  REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID ?? '#{REACT_APP_CLIENT_ID}#',
  REACT_APP_HUB_URL: process.env.REACT_APP_HUB_URL ?? 'https://ingram-grocefy-api-prod.azurewebsites.net/api/',
  REACT_APP_GEO_API_KEY: process.env.REACT_APP_GEO_API_KEY ?? 'AIzaSyCDmdwu-sIA-TIWDwjVd8qRPMpDuvr2Tqk',
  REACT_APP_PREFIX: process.env.REACT_APP_PREFIX ?? 'ingram',

  REACT_APP_DEFAULT_LANGUAGE: process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'rn',

  REACT_APP_SERVER_CONFIG: process.env.REACT_APP_SERVER_CONFIG ?? 'Endpoint=https://ingram-grocefy-prod.azconfig.io;Id=5Ofj-l0-s0:WcEFWDK+h5p6epfaKfHs;Secret=REBXLC9o2kd4XzOThem5DDo4zY+x0eBDsxWGfMnl9Fw=',
  REACT_APP_APP_CONFIG: process.env.REACT_APP_APP_CONFIG ?? 'Endpoint=https://ingram-grocefy-app.azconfig.io;Id=jzH1-l0-s0:8ox+f9hB6ZQLDXKxtPef;Secret=XMuBqdan7NdPid4JbudlDaJy/nKyoUed/rX7/mTFRDs=',
  REACT_APP_MANAGER_CONFIG: process.env.REACT_APP_MANAGER_CONFIG ?? '#{REACT_APP_MANAGER_CONFIG}#',

  REACT_APP_IMAGES_URL: process.env.REACT_APP_IMAGES_URL ?? '#{REACT_APP_IMAGES_URL}#',
  REACT_APP_IMAGES_KEY: process.env.REACT_APP_IMAGES_KEY ?? '#{REACT_APP_IMAGES_KEY}#',
  REACT_APP_UTILITY_KEY: process.env.REACT_APP_UTILITY_KEY ?? 'en',
  REACT_APP_PAYMENTS_KEY: process.env.REACT_APP_PAYMENTS_KEY ?? '9f09ee1311b041afad4a09b53bce41022e952f1576be4933b63a3af9e1860745bf6642961a6e45aba8f05363ca875473c6a4b854e1ab40e19c79b033b3f5a17e',
  REACT_APP_BOOKING_API_URL: process.env.REACT_APP_BOOKING_API_URL ?? '#{REACT_APP_BOOKING_API_URL}#',
  REACT_APP_BOOKING_API_KEY: process.env.REACT_APP_BOOKING_API_KEY ?? '#{REACT_APP_BOOKING_API_KEY}#',
  REACT_APP_TIMEZONE_OFFSET: process.env.REACT_APP_TIMEZONE_OFFSET ?? '-4',
  REACT_APP_BOOKING_CANCELLATION_FEE: process.env.REACT_APP_BOOKING_CANCELLATION_FEE ?? '15',
  BookingEventUrl: process.env.REACT_APP_BOOKING_API_EVENT_URL ?? '#{REACT_APP_BOOKING_API_EVENT_URL}#',
  SpecificProductUrl: process.env.REACT_APP_SPECIFIC_PRODUCT_URL ?? '#{REACT_APP_SPECIFIC_PRODUCT_URL}#',
  WebsiteUrl: process.env.REACT_APP_WEBSITE_URL ?? '#{REACT_APP_WEBSITE_URL}#'
};

export default envConfig;
